import { createAction } from 'redux-actions';

export const INIT_VALUES = '@scraper/INIT_VALUES';
export const INIT_VALUES_SUCCESS = '@scraper/INIT_VALUES_SUCCESS';

export const GET_SCRAPER_SUCCESS = '@scraper/GET_SCRAPER_SUCCESS';
export const GET_SCRAPER_REQUEST = '@scraper/GET_SCRAPER_REQUEST';
export const GET_SCRAPER_FAILURE = '@scraper/GET_SCRAPER_FAILURE';

export const POST_SCRAPER_REQUEST = '@scraper/POST_SCRAPER_REQUEST';
export const POST_SCRAPER_SUCCESS = '@scraper/POST_SCRAPER_SUCCESS';
export const POST_SCRAPER_FAILURE = '@scraper/POST_SCRAPER_FAILURE';

export const POST_HELPUS_REQUEST = '@scraper/POST_HELPUS_REQUEST';
export const POST_HELPUS_SUCCESS = '@scraper/POST_HELPUS_SUCCESS';
export const POST_HELPUS_FAILURE = '@scraper/POST_HELPUS_FAILURE';

export const scraperActions = {
  // Init State
  initValues: createAction(INIT_VALUES),
  initValuesSuccess: createAction(INIT_VALUES_SUCCESS),

  // Get Scraped data
  getScraperRequest: createAction(GET_SCRAPER_REQUEST),
  getScraperSuccess: createAction(GET_SCRAPER_SUCCESS),
  getScraperFailure: createAction(GET_SCRAPER_FAILURE),

  // Post Scraped data
  postScraperRequest: createAction(POST_SCRAPER_REQUEST),
  postScraperSuccess: createAction(POST_SCRAPER_SUCCESS),
  postScraperFailure: createAction(POST_SCRAPER_FAILURE),

  // Help us
  postHelpUsRequest: createAction(POST_HELPUS_REQUEST),
  postHelpUsSuccess: createAction(POST_HELPUS_SUCCESS),
  postHelpUsFailure: createAction(POST_HELPUS_FAILURE),
};
