import { all, put, call, takeLatest } from "redux-saga/effects";
import * as api from "../../utils/api";
import extractErrors from "../../utils/error";

import {
  INIT_VALUES,
  CREATE_USER_REQUEST,
  LOGIN_USER_REQUEST,
  PHONE_VERIFY_REQUEST,
  LOGIN_2FA_REQUEST,
  USER_PROFILE_REQUEST,
  LOGOUT_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  EMAIL_CONFIRM_REQUEST,
  RESEND_CONFIRM_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_SECURITY_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  ADD_CARD_REQUEST,
  DELETE_CARD_REQUEST,
  authActions,
} from "./actions";

export function* initValues({ payload }) {
  yield put(authActions.initValuesSuccess(payload));
}

export function* resetPassword({ payload }) {
  try {
    yield call(api.resetPassword, payload);
    yield put(authActions.resetPwdSuccess());
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.resetPwdFailure({ errors }));
  }
}

export function* resendConfirmEmail({ payload }) {
  try {
    yield call(api.resendConfirmEmail, payload);
    yield put(authActions.resendConfirmSuccess());
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.resendConfirmFailure({ errors }));
  }
}

export function* confirmEmail({ payload }) {
  try {
    const response = yield call(api.confirmEmail, payload);
    localStorage.setItem('phonenumber', response.data.data.phonenumber.replace(/\+/g, ''));

    yield put(authActions.emailConfirmSuccess());


  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.emailConfirmFailure({ errors }));
  }
}

export function* createUser({ payload }) {
  try {
    yield call(api.createUser, payload);

    yield put(authActions.createUserSuccess({}));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.createUserFailure({ errors }));
  }
}

export function* phoneVerify({ payload }) {
  try {
    const response = yield call(api.phoneVerify, payload);

    const user = response.data.data;
    yield put(authActions.loginUserSuccess({ user }));
    yield put(authActions.phoneVerifySuccess({}));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.phoneVerifyFailure({ errors }));
  }
}


export function* loginUser({ payload }) {
  try {
    const response = yield call(api.loginUser, payload);

    const { user, token } = response.data;
    localStorage.setItem('token', token);
    yield put(authActions.loginUserSuccess({ user }));
  } catch (err) {
    const errors = [
      {
        code: 401,
        message: "CREDENTIAL_WRONG",
      },
    ];
    yield put(authActions.loginUserFailure({ errors }));
  }
}

export function* login2FA({ payload }) {
  try {
    const response = yield call(api.twoFaVerify, payload);
    const user = response.data.data;

    yield put(authActions.login2FASuccess({ user }));
  } catch (err) {
    if (err.response.status === 429) {
      const errors = [
        {
          code: 429,
          message: "too_many_requests",
        },
      ];
      yield put(authActions.login2FAFailure({ errors }));
      return;
    }
    const errors = extractErrors(err);
    yield put(
      authActions.login2FAFailure({
        user: payload,
        errors,
      })
    );
    let error = errors.find((errObj) => errObj.code === 642);
    if (error) {
      yield put(
        authActions.loginUserFailure({
          user: payload,
          errors,
        })
      );
      return;
    }
    error = errors.find(
      (errObj) =>
        errObj.code === 622 || errObj.code === 627 || errObj.code === 628
    );
    if (error) {

    }
  }
}

export function* userProfile({ payload }) {
  try {
    const response = yield call(api.userProfile, payload);

    const user = response.data.user;
    yield put(authActions.userProfileSuccess({ user }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.userProfileFailure({ errors }));
  }
}

export function* logout() {
  try {
    localStorage.removeItem('token');
    yield put(authActions.logoutSuccess());
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.logoutFailure({ errors }));
  }
}

export function* forgotPassword({ payload }) {
  try {
    yield call(api.forgotPassword, payload);
    yield put(authActions.forgotPasswordSuccess());
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.forgotPasswordFailure({ errors }));
  }
}

export function* updateProfile({ payload }) {
  try {
    const response = yield call(api.updateProfile, payload);
    const user = response.data;
    yield put(authActions.updateProfileSuccess({ user }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.updateProfileFailure({ errors }));
  }
}

export function* updateSecurity({ payload }) {
  try {
    const response = yield call(api.updateSecurity, payload);
    const user = response.data.data;
    yield put(authActions.updateSecuritySuccess({ user }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.updateSecurityFailure({ errors }));
  }
}

export function* changePassword({ payload }) {
  try {
    yield call(api.changePassword, payload);
    yield put(authActions.changePwdSuccess());
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.changePwdFailure({ errors }));
  }
}

export function* addCard({ payload }) {
  try {
    const response = yield call(api.addCard, payload);

    const card = response.data;
    yield put(authActions.addCardSuccess({ card }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.addCardFailure({ errors }));
  }
}

export function* deleteCard({ payload }) {
  try {
    const response = yield call(api.deleteCard, payload);

    const card = response.data;
    yield put(authActions.deleteCardSuccess({ card: card || null }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(authActions.deleteCardFailure({ errors }));
  }
}

export default function* () {
  yield all([
    takeLatest(INIT_VALUES, initValues),
    takeLatest(CREATE_USER_REQUEST, createUser),
    takeLatest(LOGIN_USER_REQUEST, loginUser),
    takeLatest(LOGIN_2FA_REQUEST, login2FA),
    takeLatest(USER_PROFILE_REQUEST, userProfile),
    takeLatest(LOGOUT_REQUEST, logout),
    takeLatest(PHONE_VERIFY_REQUEST, phoneVerify),
    takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(EMAIL_CONFIRM_REQUEST, confirmEmail),
    takeLatest(RESEND_CONFIRM_REQUEST, resendConfirmEmail),
    takeLatest(RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(UPDATE_USER_PROFILE_REQUEST, updateProfile),
    takeLatest(UPDATE_USER_SECURITY_REQUEST, updateSecurity),
    takeLatest(CHANGE_PASSWORD_REQUEST, changePassword),
    takeLatest(ADD_CARD_REQUEST, addCard),
    takeLatest(DELETE_CARD_REQUEST, deleteCard),
  ]);
}
