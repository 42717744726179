import { initialState } from './selectors';

import {
  INIT_VALUES_SUCCESS,
  RESET_AD,

  GET_ADS_LIST_REQUEST,
  GET_ADS_LIST_SUCCESS,
  GET_ADS_LIST_FAILURE,

  CREATE_ADS_REQUEST,
  CREATE_ADS_SUCCESS,
  CREATE_ADS_FAILURE,

  UPDATE_ADS_REQUEST,
  UPDATE_ADS_SUCCESS,
  UPDATE_ADS_FAILURE,
} from './actions';

export default function (state = initialState, action = {}) {

  switch (action.type) {
    case INIT_VALUES_SUCCESS:
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach(stateName => {
          if (initialState[stateName] !== undefined) {
            state[stateName] = initialState[stateName];
          }
        });
      }
      return {
        ...state,
      };

    // empty previous ad
    case RESET_AD:
      return {
        ...state,
        ads: null
      }
    // Get ADS List
    case GET_ADS_LIST_REQUEST:
      return {
        ...state,
        getAdsListStatus: 'running',
      };
    case GET_ADS_LIST_SUCCESS:
      return {
        ...state,
        adsList: action.payload.adsList,
        getAdsListStatus: 'success',
      };
    case GET_ADS_LIST_FAILURE:
      return {
        ...state,
        getAdsListStatus: 'failure',
        getAdsListErrors: action.payload.errors,
      };

    // Create ADS
    case CREATE_ADS_REQUEST:
      return {
        ...state,
        createAdsStatus: 'running',
      };
    case CREATE_ADS_SUCCESS:
      return {
        ...state,
        ads: action.payload.ads,
        createAdsStatus: 'success',
      };
    case CREATE_ADS_FAILURE:
      return {
        ...state,
        createAdsStatus: 'failure',
        createAdsErrors: action.payload.errors,
      };

    // Update ADS
    case UPDATE_ADS_REQUEST:
      return {
        ...state,
        updateAdsStatus: 'running',
      };
    case UPDATE_ADS_SUCCESS:
      return {
        ...state,
        ads: action.payload.ads,
        updateAdsStatus: 'success',
      };
    case UPDATE_ADS_FAILURE:
      return {
        ...state,
        updateAdsStatus: 'failure',
        updateAdsErrors: action.payload.errors,
      };


    default:
      return state;
  }
}
