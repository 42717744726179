import { createAction } from 'redux-actions';

export const INIT_VALUES = '@ads/INIT_VALUES';
export const INIT_VALUES_SUCCESS = '@ads/INIT_VALUES_SUCCESS';

export const GET_STATISTIC_SUCCESS = '@ads/GET_STATISTIC_SUCCESS';
export const GET_STATISTIC_REQUEST = '@ads/GET_STATISTIC_REQUEST';
export const GET_CAMPAIGN_LIST_REQUEST = '@ads/GET_CAMPAIGN_LIST_REQUEST';
export const GET_CAMPAIGN_LIST_SUCCESS = '@ads/GET_CAMPAIGN_LIST_SUCCESS';
export const GET_CAMPAIGN_LIST_FAILURE = '@ads/GET_CAMPAIGN_LIST_FAILURE';

export const CREATE_CAMPAIGN_REQUEST = '@ads/CREATE_CAMPAIGN_REQUEST';
export const CREATE_CAMPAIGN_SUCCESS = '@ads/CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = '@ads/CREATE_CAMPAIGN_FAILURE';

export const UPDATE_CAMPAIGN_REQUEST = '@ads/UPDATE_CAMPAIGN_REQUEST';
export const UPDATE_CAMPAIGN_SUCCESS = '@ads/UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAILURE = '@ads/UPDATE_CAMPAIGN_FAILURE';

export const GET_CAMPAIGN_REQUEST = '@ads/GET_CAMPAIGN_REQUEST';
export const GET_CAMPAIGN_SUCCESS = '@ads/GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = '@ads/GET_CAMPAIGN_FAILURE';

export const DELETE_CAMPAIGN_REQUEST = '@ads/DELETE_CAMPAIGN_REQUEST';
export const DELETE_CAMPAIGN_SUCCESS = '@ads/DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAILURE = '@ads/DELETE_CAMPAIGN_FAILURE';

export const campaignActions = {
  // Init State
  initValues: createAction(INIT_VALUES),
  initValuesSuccess: createAction(INIT_VALUES_SUCCESS),

  // Get Campaign List
  getCampaignRequest: createAction(GET_CAMPAIGN_REQUEST),
  getCampaignSuccess: createAction(GET_CAMPAIGN_SUCCESS),
  getCampaignFailure: createAction(GET_CAMPAIGN_FAILURE),

  getStatisticRequest: createAction(GET_STATISTIC_REQUEST),
  getStatisticSuccess: createAction(GET_STATISTIC_SUCCESS),

  // Get Campaign List
  getCampaignListRequest: createAction(GET_CAMPAIGN_LIST_REQUEST),
  getCampaignListSuccess: createAction(GET_CAMPAIGN_LIST_SUCCESS),
  getCampaignListFailure: createAction(GET_CAMPAIGN_LIST_FAILURE),

  // Create Campaign
  createCampaignRequest: createAction(CREATE_CAMPAIGN_REQUEST),
  createCampaignSuccess: createAction(CREATE_CAMPAIGN_SUCCESS),
  createCampaignFailure: createAction(CREATE_CAMPAIGN_FAILURE),

  // Update Campaign
  updateCampaignRequest: createAction(UPDATE_CAMPAIGN_REQUEST),
  updateCampaignSuccess: createAction(UPDATE_CAMPAIGN_SUCCESS),
  updateCampaignFailure: createAction(UPDATE_CAMPAIGN_FAILURE),

  // DELETE Campaign
  deleteCampaignRequest: createAction(DELETE_CAMPAIGN_REQUEST),
  deleteCampaignSuccess: createAction(DELETE_CAMPAIGN_SUCCESS),
  deleteCampaignFailure: createAction(DELETE_CAMPAIGN_FAILURE),
};
