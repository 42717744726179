import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/configureStore';
const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ));

ReactDOM.render(
  <Provider store={configureStore()}>
    <React.Suspense fallback={<div className="loading" />}>
      <App />
    </React.Suspense>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
