export const initialState = {
  user: null,
  loginStatus: 'idle',
  loginErrors: [],
  login2FAStatus: 'idle',
  login2FAErrors: [],
  logoutStatus: 'idle',
  profileStatus: 'idle',
  logoutErrors: null,
  forgotStatus: 'idle',
  forgotErrors: null,
  registerStatus: 'idle',
  registerErrors: null,
  emailConfirmStatus: 'idle',
  emailConfirmErrors: null,
  resendConfirmStatus: 'idle',
  resendConfirmErrors: null,
  resetPwdStatus: 'idle',
  resetPwdErrors: null,
  updateProfileStatus: 'idle',
  updateProfileErrors: null,
  updateSecurityStatus: 'idle',
  updateSecurityErrors: null,
  phoneVerifyStatus: 'idle',
  phoneVerifyErrors: null,
  changePwdStatus: 'idle',
  changePwdErrors: null,
  addCardStatus: 'idle',
  addCardErrors: null,
  deleteCardStatus: 'idle',
  deleteCardErrors: null,
};

export const user = (state = initialState) => (
  state.user || initialState.user
);
export const loginStatus = (state = initialState) => (
  state.loginStatus || initialState.loginStatus
);
export const profileStatus = (state = initialState) => (
  state.profileStatus || initialState.profileStatus
);
export const isAuthenticated = (state = initialState) => {
  const status = loginStatus(state);
  const profile = profileStatus(state);
  if (profile === 'running' || profile === 'idle') return true;
  return status === 'success' && !!user(state);
};
export const loginErrors = (state = initialState) => (
  state.loginErrors || initialState.loginErrors
);
export const login2FAStatus = (state = initialState) => (
  state.login2FAStatus || initialState.login2FAStatus
);
export const login2FAErrors = (state = initialState) => (
  state.login2FAErrors || initialState.login2FAErrors
);
export const logoutStatus = (state = initialState) => (
  state.logoutStatus || initialState.logoutStatus
);
export const logoutErrors = (state = initialState) => (
  state.logoutErrors || initialState.logoutErrors
);
export const forgotStatus = (state = initialState) => (
  state.forgotStatus || initialState.forgotStatus
);
export const forgotErrors = (state = initialState) => (
  state.forgotErrors || initialState.forgotErrors
);
export const registerStatus = (state = initialState) => (
  state.registerStatus || initialState.registerStatus
);
export const registerErrors = (state = initialState) => (
  state.registerErrors || initialState.registerErrors
);
export const emailConfirmStatus = (state = initialState) => (
  state.emailConfirmStatus || initialState.emailConfirmStatus
);
export const emailConfirmErrors = (state = initialState) => (
  state.emailConfirmErrors || initialState.emailConfirmErrors
);
export const resendConfirmStatus = (state = initialState) => (
  state.resendConfirmStatus || initialState.resendConfirmStatus
);
export const resendConfirmErrors = (state = initialState) => (
  state.resendConfirmErrors || initialState.resendConfirmErrors
);
export const resetPwdStatus = (state = initialState) => (
  state.resetPwdStatus || initialState.resetPwdStatus
);
export const resetPwdErrors = (state = initialState) => (
  state.resetPwdErrors || initialState.resetPwdErrors
);
export const updateProfileStatus = (state = initialState) => (
  state.updateProfileStatus || initialState.updateProfileStatus
);
export const updateProfileErrors = (state = initialState) => (
  state.updateProfileErrors || initialState.updateProfileErrors
);
export const updateSecurityStatus = (state = initialState) => (
  state.updateSecurityStatus || initialState.updateSecurityStatus
);
export const updateSecurityErrors = (state = initialState) => (
  state.updateSecurityErrors || initialState.updateSecurityErrors
);
export const phoneVerifyStatus = (state = initialState) => (
  state.phoneVerifyStatus || initialState.phoneVerifyStatus
);
export const phoneVerifyErrors = (state = initialState) => (
  state.phoneVerifyErrors || initialState.phoneVerifyErrors
);
export const changePwdStatus = (state = initialState) => (
  state.changePwdStatus || initialState.changePwdStatus
);
export const changePwdErrors = (state = initialState) => (
  state.changePwdErrors || initialState.changePwdErrors
);
export const addCardStatus = (state = initialState) => (
  state.addCardStatus || initialState.addCardStatus
);
export const addCardErrors = (state = initialState) => (
  state.addCardErrors || initialState.addCardErrors
);
export const deleteCardStatus = (state = initialState) => (
  state.deleteCardStatus || initialState.deleteCardStatus
);
export const deleteCardErrors = (state = initialState) => (
  state.deleteCardErrors || initialState.deleteCardErrors
);