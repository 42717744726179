import axios from 'axios';
import { config } from 'config';

export function getBaseURL() {
  return config.API_BASE;
}

axios.defaults.baseURL = getBaseURL();
axios.defaults.timeout = 600000; // Max time limit: 30s
axios.defaults.method = 'GET';

function jsonConfig(config) {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  };
  return config;
}

function request(config) {
  if (config.data) {
    jsonConfig(config);
  }
  const token = localStorage.getItem('token');
  config.headers = {
    ...config.headers,
    authorization: token ? `Bearer ${token}` : undefined,
  };

  return axios.request(config);
}

/**
 * Auth APIs
 */

export function createUser(payload) {
  return request({
    url: '/auth/kw_signup',
    method: 'POST',
    data: payload,
  });
}

export function loginUser({ email, password }) {
  return request({
    url: '/auth/login',
    method: 'POST',
    data: { email, password },
  });
}

export function resetPassword({ email }) {
  return request({
    url: '/auth/resetPassword',
    method: 'POST',
    data: { email },
  });
}

export function resendConfirmEmail({ email }) {
  return request({
    url: '/auth/verification/email/code',
    method: 'POST',
    data: { email },
  });
}

export function changePasswordByUser(userId, password) {
  return request({
    url: '/auth/changePassword/' + userId,
    method: 'POST',
    data: { password },
  });
}

export function confirmEmail({ token }) {
  return request({
    url: '/auth/verification/email/check',
    method: 'POST',
    data: { token },
  });
}

export function phoneVerify({
  login,
  verificationcode,
}) {
  return request({
    url: '/auth/verification/phone/check',
    method: 'POST',
    data: {
      login,
      verificationcode
    },
  });
}

export function requestPhoneCode({
  login,
  password,
  phonenumber
}) {
  return request({
    url: '/auth/verification/phone/code',
    method: 'POST',
    data: {
      login,
      password,
      phonenumber
    },
  });
}

export function request2FACode({
  login
}) {
  return request({
    url: '/auth/verification/twofactor/request',
    method: 'POST',
    data: {
      login
    },
  });
}

export function twoFaVerify({
  login,
  verificationcode,
}) {
  return request({
    url: '/auth/verification/twofactor/check',
    method: 'POST',
    data: {
      login,
      verificationcode
    },
  });
}

export function forgotPassword({ email }) {
  return request({
    url: '/auth/forgot-password',
    method: 'POST',
    data: { email },
  });
}

/**
 * User APIs
 */

export function userProfile(id) {
  return request({
    url: `/user/${id}`,
    method: 'GET',
  });
}

export function updateProfile(data) {
  const body = data.img ? data.formData : data;
  return request({
    url: `/user/${data.id}`,
    method: 'PUT',
    data: body,
  });
}

export function getOriginalImage() {
  return request({
    url: '/user/profile/original_image',
    method: 'GET'
  });
}

export function updateSecurity(data) {
  return request({
    url: '/user/security',
    method: 'POST',
    data,
  });
}

export function changePassword(data) {
  return request({
    url: '/user/change-password',
    method: 'POST',
    data,
  });
}

export function createAgency(payload) {
  return request({
    url: '/auth/signup',
    method: 'POST',
    data: payload,
  });
}

export function getAgencyList(payload) {
  return request({
    url: '/user/agency/list',
    params: payload,
  });
}

export function createAgent(payload) {
  return request({
    url: '/user/agent',
    method: 'POST',
    data: payload,
  });
}

export function deleteAgent(payload) {
  return request({
    url: `/user/${payload}`,
    method: 'DELETE'
  });
}

export function getAgentList() {
  return request({
    url: '/user/agent'
  });
}

export function helpUs(payload) {
  return request({
    url: '/user/helpus',
    method: 'POST',
    data: payload,
  });
}

/**
 * Ads APIs
*/

export function getAdsList(payload) {
  return request({
    url: '/ads/list',
    params: payload,
  });
}

export function getAds(payload) {
  return request({
    url: '/ads',
    params: payload,
  });
}

export function createAds(payload) {
  return request({
    url: '/listingAd',
    method: 'POST',
    data: payload
  });
}

export function updateAds(payload) {
  return request({
    url: '/listingAd/' + payload.id,
    method: 'PUT',
    data: payload.formData,
  });
}

export function deleteAds(payload) {
  return request({
    url: '/ads',
    method: 'DELETE',
    data: payload,
  });
}

/**
 * Scraper APIs
*/

export function getScrapedData(payload) {
  return request({
    url: '/listing',
    params: { extAgentId: payload.toString() },
  });
}

export function postScrapedData(payload) {
  let postScrapedData = request({
    url: '/listing',
    method: 'POST',
    data: {
      extAgentId: payload.toString(),
    },

  });
  return postScrapedData
}

/**
 * Campaign APIs
*/
export function getCampaign(payload) {
  return request({
    url: '/campaignone',
    params: payload ? { id: payload } : '',
  });
}

export function getCampaignByListing(payload) {
  return request({
    url: '/campaign/byListingAd/' + payload,
    // params: payload ? { id: payload } : '',
  });
}

export function getCampaignList() {
  return request({
    url: '/campaign',
  });
}

export function createCampaign(payload) {
  return request({
    url: '/campaign',
    method: 'POST',
    data: payload
  });
}

export function updateCampaign(payload) {
  return request({
    url: `/campaign/${payload._id}`,
    method: 'PUT',
    data: payload,
  });
}

export function deleteCampaign(payload) {
  return request({
    url: '/campaign/',
    method: 'DELETE',
    data: payload
  });
}

export function fbGeoLoaction(payload) {
  return request({
    url: '/campaign/fblocation',
    params: payload,
  });
}

export function getEstimatedUser(payload) {
  return request({
    url: '/campaign/estimatedReach',
    method: 'POST',
    data: payload,
  });
}

export function getAllCampaignStatistics() {
  return request({
    url: '/campaign/allcampaignstats'
  });
}

export function getCampaignStatistics(id, user) {
  const data = user ? { withUser: true } : {};
  return request({
    url: `/campaign/campaignstats/${id}`,
    params: data,
  });
}

export function getStatsOfCampaignForVendor(campaignId) {
  return request({
    url: `/vendor/campaignstatistics/${campaignId}`,
  });
}

export function getListingOfCampaignForVendor(listingId) {
  return request({
    url: `/vendor/getListing/${listingId}`,
  });
}

export function createAfterNote(data) {
  return request({
    url: '/listingAd/afternoteimage',
    method: 'POST',
    data: data,
  });
}

export function getPdf() {
  return request({
    url: '/campaign/fetchpdf',
    responseType: 'arraybuffer',
  });
}

export function stopFbCompaign(payload) {
  return request({
    url: `/campaign/stopFbCompaign/${payload}`,
    method: 'POST',
  });
}

export function createPdf(payload) {
  return request({
    url: '/campaign/exportpdf',
    method: 'POST',
    data: payload,
  });
}

/**
 * Card APIs
*/
export function addCard(payload) {
  return request({
    url: '/payment',
    method: 'POST',
    data: payload,
  });
}

export function deleteCard(payload) {
  return request({
    url: `/payment/${payload}`,
    method: 'DELETE',
  });
}

export default axios;
