import { createAction } from 'redux-actions';

export const INIT_VALUES = '@auth/INIT_VALUES';
export const INIT_VALUES_SUCCESS = '@auth/INIT_VALUES_SUCCESS';

export const CREATE_USER_REQUEST = '@auth/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = '@auth/CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = '@auth/CREATE_USER_FAILURE';

export const EMAIL_CONFIRM_REQUEST = '@auth/EMAIL_CONFIRM_REQUEST';
export const EMAIL_CONFIRM_SUCCESS = '@auth/EMAIL_CONFIRM_SUCCESS';
export const EMAIL_CONFIRM_FAILURE = '@auth/EMAIL_CONFIRM_FAILURE';

export const PHONE_VERIFY_REQUEST = '@auth/PHONE_VERIFY_REQUEST';
export const PHONE_VERIFY_SUCCESS = '@auth/PHONE_VERIFY_SUCCESS';
export const PHONE_VERIFY_FAILURE = '@auth/PHONE_VERIFY_FAILURE';

export const LOGIN_USER_REQUEST = '@auth/LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = '@auth/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = '@auth/LOGIN_USER_FAILURE';

export const USER_PROFILE_REQUEST = '@auth/USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = '@auth/USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = '@auth/USER_PROFILE_FAILURE';

export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = '@auth/LOGOUT_FAILURE';

export const LOGIN_2FA_REQUEST = '@auth/LOGIN_2FA_REQUEST';
export const LOGIN_2FA_SUCCESS = '@auth/LOGIN_2FA_SUCCESS';
export const LOGIN_2FA_FAILURE = '@auth/LOGIN_2FA_FAILURE';

export const FORGOT_PASSWORD_REQUEST = '@auth/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = '@auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = '@auth/FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = '@auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = '@auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = '@auth/RESET_PASSWORD_FAILURE';

export const RESEND_CONFIRM_REQUEST = '@auth/RESEND_CONFIRM_REQUEST';
export const RESEND_CONFIRM_SUCCESS = '@auth/RESEND_CONFIRM_SUCCESS';
export const RESEND_CONFIRM_FAILURE = '@auth/RESEND_CONFIRM_FAILURE';

export const UPDATE_USER_PROFILE_REQUEST = '@auth/UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = '@auth/UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = '@auth/UPDATE_USER_PROFILE_FAILURE';

export const UPDATE_USER_SECURITY_REQUEST = '@auth/UPDATE_USER_SECURITY_REQUEST';
export const UPDATE_USER_SECURITY_SUCCESS = '@auth/UPDATE_USER_SECURITY_SUCCESS';
export const UPDATE_USER_SECURITY_FAILURE = '@auth/UPDATE_USER_SECURITY_FAILURE';

export const CHANGE_PASSWORD_REQUEST = '@auth/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = '@auth/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = '@auth/CHANGE_PASSWORD_FAILURE';

export const ADD_CARD_REQUEST = '@card/ADD_CARD_REQUEST';
export const ADD_CARD_SUCCESS = '@card/ADD_CARD_SUCCESS';
export const ADD_CARD_FAILURE = '@card/ADD_CARD_FAILURE';

export const DELETE_CARD_REQUEST = '@card/DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = '@card/DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = '@card/DELETE_CARD_FAILURE';

export const authActions = {
  // Init State
  initValues: createAction(INIT_VALUES),
  initValuesSuccess: createAction(INIT_VALUES_SUCCESS),

  // Register
  createUserRequest: createAction(CREATE_USER_REQUEST),
  createUserSuccess: createAction(CREATE_USER_SUCCESS),
  createUserFailure: createAction(CREATE_USER_FAILURE),

  // Phone Verify
  phoneVerifyRequest: createAction(PHONE_VERIFY_REQUEST),
  phoneVerifySuccess: createAction(PHONE_VERIFY_SUCCESS),
  phoneVerifyFailure: createAction(PHONE_VERIFY_FAILURE),

  // Login
  loginUserRequest: createAction(LOGIN_USER_REQUEST),
  loginUserSuccess: createAction(LOGIN_USER_SUCCESS),
  loginUserFailure: createAction(LOGIN_USER_FAILURE),

  login2FARequest: createAction(LOGIN_2FA_REQUEST),
  login2FASuccess: createAction(LOGIN_2FA_SUCCESS),
  login2FAFailure: createAction(LOGIN_2FA_FAILURE),

  // Profile
  userProfileRequest: createAction(USER_PROFILE_REQUEST),
  userProfileSuccess: createAction(USER_PROFILE_SUCCESS),
  userProfileFailure: createAction(USER_PROFILE_FAILURE),

  // Logout
  logoutRequest: createAction(LOGOUT_REQUEST),
  logoutSuccess: createAction(LOGOUT_SUCCESS),
  logoutFailure: createAction(LOGOUT_FAILURE),

  // Forgot password
  forgotPasswordRequest: createAction(FORGOT_PASSWORD_REQUEST),
  forgotPasswordSuccess: createAction(FORGOT_PASSWORD_SUCCESS),
  forgotPasswordFailure: createAction(FORGOT_PASSWORD_FAILURE),
  
  // Email Confirm
  emailConfirmRequest: createAction(EMAIL_CONFIRM_REQUEST),
  emailConfirmSuccess: createAction(EMAIL_CONFIRM_SUCCESS),
  emailConfirmFailure: createAction(EMAIL_CONFIRM_FAILURE),

  // Resend Email Confirm
  resendConfirmRequest: createAction(RESEND_CONFIRM_REQUEST),
  resendConfirmSuccess: createAction(RESEND_CONFIRM_SUCCESS),
  resendConfirmFailure: createAction(RESEND_CONFIRM_FAILURE),

  // Reset Password
  resetPwdRequest: createAction(RESET_PASSWORD_REQUEST),
  resetPwdSuccess: createAction(RESET_PASSWORD_SUCCESS),
  resetPwdFailure: createAction(RESET_PASSWORD_FAILURE),

  // Update Profile
  updateProfileRequest: createAction(UPDATE_USER_PROFILE_REQUEST),
  updateProfileSuccess: createAction(UPDATE_USER_PROFILE_SUCCESS),
  updateProfileFailure: createAction(UPDATE_USER_PROFILE_FAILURE),

  // Update Security
  updateSecurityRequest: createAction(UPDATE_USER_SECURITY_REQUEST),
  updateSecuritySuccess: createAction(UPDATE_USER_SECURITY_SUCCESS),
  updateSecurityFailure: createAction(UPDATE_USER_SECURITY_FAILURE),

  //Change Password
  changePwdRequest: createAction(CHANGE_PASSWORD_REQUEST),
  changePwdSuccess: createAction(CHANGE_PASSWORD_SUCCESS),
  changePwdFailure: createAction(CHANGE_PASSWORD_FAILURE),

  //Add card
  addCardRequest: createAction(ADD_CARD_REQUEST),
  addCardSuccess: createAction(ADD_CARD_SUCCESS),
  addCardFailure: createAction(ADD_CARD_FAILURE),

  //Delete card
  deleteCardRequest: createAction(DELETE_CARD_REQUEST),
  deleteCardSuccess: createAction(DELETE_CARD_SUCCESS),
  deleteCardFailure: createAction(DELETE_CARD_FAILURE),
};
