/* eslint-disable generator-star-spacing */
import { toast } from 'react-toastify';
import { all, put, call, takeLatest } from 'redux-saga/effects';

import * as api from 'utils/api';
import extractErrors from 'utils/error';
import {
  INIT_VALUES,
  GET_CAMPAIGN_REQUEST,
  GET_CAMPAIGN_LIST_REQUEST,
  CREATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_REQUEST,
  GET_STATISTIC_REQUEST,
  campaignActions,
} from './actions';

export function* initValues({ payload }) {
  yield put(campaignActions.initValuesSuccess(payload));
}

export function* getCampaign({ payload }) {
  try {
    const response = yield call(api.getCampaign, payload);
    const campaign = response.data.campaign;
    yield put(campaignActions.getCampaignSuccess({ campaign }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(campaignActions.getCampaignFailure({ errors }));
  }
}

export function* getCampaignList({ payload }) {
  try {
    const response = yield call(api.getCampaignList, payload);
    const campaignList = response.data;
    yield put(campaignActions.getCampaignListSuccess({ campaignList }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(campaignActions.getCampaignListFailure({ errors }));
  }
}

export function* createCampaign({ payload }) {
  try {
    const response = yield call(api.createCampaign, payload);
    const campaign = response.data.campaign;
    yield put(campaignActions.createCampaignSuccess({ campaign }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(campaignActions.createCampaignFailure({ errors }));
  }
}

export function* updateCampaign({ payload }) {
  try {
    const response = yield call(api.updateCampaign, payload);
    const campaign = response.data.campaign;
    yield put(campaignActions.updateCampaignSuccess({ campaign }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(campaignActions.updateCampaignFailure({ errors }));
  }
}

export function* deleteCampaign({ payload }) {
  const campaigns = payload.campaigns.filter(e => e._id !== payload.id);
  const campaignObj = payload.campaigns.find(campaign => campaign._id === payload.id)
  try {
    const data = {
      id: payload.id,
      resourceId: campaignObj.campaignResourceId,
    }
    const response = yield call(api.deleteCampaign, data);
    const campaign = response.data;

    toast.success('Campaign successfully deleted');
    yield put(campaignActions.deleteCampaignSuccess({ campaign, campaigns }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(campaignActions.deleteCampaignFailure({ errors, campaigns }));
  }
}

export function* getStatistic() {
  try {
    const response = yield call(api.getAllCampaignStatistics);
    const campaignStatistic = response.data;

    yield put(campaignActions.getStatisticSuccess({ campaignStatistic }));
  } catch (err) { }
}

export default function* () {
  yield all([
    takeLatest(INIT_VALUES, initValues),
    takeLatest(GET_CAMPAIGN_REQUEST, getCampaign),
    takeLatest(GET_CAMPAIGN_LIST_REQUEST, getCampaignList),
    takeLatest(CREATE_CAMPAIGN_REQUEST, createCampaign),
    takeLatest(UPDATE_CAMPAIGN_REQUEST, updateCampaign),
    takeLatest(DELETE_CAMPAIGN_REQUEST, deleteCampaign),
    takeLatest(GET_STATISTIC_REQUEST, getStatistic),
  ]);
}
