export const initialState = {
  campaignList: null,
  campaignStatistic: null,
  getCampaignListStatus: 'idle',
  getCampaignListErrors: [],
  campaign: null,
  createCampaignStatus: 'idle',
  createCampaignErrors: [],
  updateCampaignStatus: 'idle',
  updateCampaignErrors: [],
  campaignItem: null,
  getCampaignErrors: [],
  getCampaignStatus: 'idle',
  deleteCampaign: null,
  deleteCampaignErrors: [],
  deleteCampaignStatus: 'idle',
};

export const campaignItem = (state = initialState) => (
  state.campaignItem || initialState.campaignItem
);
export const campaignStatistic = (state = initialState) => (
  state.campaignStatistic || initialState.campaignStatistic
);
export const getCampaignStatus = (state = initialState) => (
  state.getCampaignStatus || initialState.getCampaignStatus
);
export const getCampaignErrors = (state = initialState) => (
  state.getCampaignErrors || initialState.getCampaignErrors
);
export const campaignList = (state = initialState) => (
  state.campaignList || initialState.campaignList
);
export const getCampaignListStatus = (state = initialState) => (
  state.getCampaignListStatus || initialState.getCampaignListStatus
);
export const getCampaignListErrors = (state = initialState) => (
  state.getCampaignListErrors || initialState.getCampaignListErrors
);
export const campaign = (state = initialState) => (
  state.campaign || initialState.campaign
);
export const createCampaignStatus = (state = initialState) => (
  state.createCampaignStatus || initialState.createCampaignStatus
);
export const createCampaignErrors = (state = initialState) => (
  state.createCampaignErrors || initialState.createCampaignErrors
);
export const updateCampaignStatus = (state = initialState) => (
  state.updateCampaignStatus || initialState.updateCampaignStatus
);
export const updateCampaignErrors = (state = initialState) => (
  state.updateCampaignErrors || initialState.updateCampaignErrors
);
export const deleteCampaign = (state = initialState) => (
  state.deleteCampaign || initialState.deleteCampaign
);
export const deleteCampaignErrors = (state = initialState) => (
  state.deleteCampaignErrors || initialState.deleteCampaignErrors
);
export const deleteCampaignStatus = (state = initialState) => (
  state.deleteCampaignStatus || initialState.deleteCampaignStatus
);
