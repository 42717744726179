import { createAction } from 'redux-actions';

export const INIT_VALUES = '@ads/INIT_VALUES';
export const INIT_VALUES_SUCCESS = '@ads/INIT_VALUES_SUCCESS';


export const RESET_AD = '@ads/RESET_AD';

export const GET_ADS_LIST_REQUEST = '@ads/GET_ADS_LIST_REQUEST';
export const GET_ADS_LIST_SUCCESS = '@ads/GET_ADS_LIST_SUCCESS';
export const GET_ADS_LIST_FAILURE = '@ads/GET_ADS_LIST_FAILURE';

export const CREATE_ADS_REQUEST = '@ads/CREATE_ADS_REQUEST';
export const CREATE_ADS_SUCCESS = '@ads/CREATE_ADS_SUCCESS';
export const CREATE_ADS_FAILURE = '@ads/CREATE_ADS_FAILURE';

export const UPDATE_ADS_REQUEST = '@ads/UPDATE_ADS_REQUEST';
export const UPDATE_ADS_SUCCESS = '@ads/UPDATE_ADS_SUCCESS';
export const UPDATE_ADS_FAILURE = '@ads/UPDATE_ADS_FAILURE';

export const adsActions = {
  // Init State
  initValues: createAction(INIT_VALUES),
  initValuesSuccess: createAction(INIT_VALUES_SUCCESS),
  resetAds: createAction(RESET_AD),
  // Get ADS List
  getAdsListRequest: createAction(GET_ADS_LIST_REQUEST),
  getAdsListSuccess: createAction(GET_ADS_LIST_SUCCESS),
  getAdsListFailure: createAction(GET_ADS_LIST_FAILURE),

  // Create ADS
  createAdsRequest: createAction(CREATE_ADS_REQUEST),
  createAdsSuccess: createAction(CREATE_ADS_SUCCESS),
  createAdsFailure: createAction(CREATE_ADS_FAILURE),

  // Update ADS
  updateAdsRequest: createAction(UPDATE_ADS_REQUEST),
  updateAdsSuccess: createAction(UPDATE_ADS_SUCCESS),
  updateAdsFailure: createAction(UPDATE_ADS_FAILURE),

};
