export const initialState = {
  scrapedData: [],
  getScraperStatus: 'idle',
  getScraperErrors: [],
  postScraperStatus: 'idle',
  postScraperErrors: [],
  helpUsData: null,
  postHelpUsStatus: 'idle',
  postHelpUsErrors: []
};

export const helpUsData = (state = initialState) => (
  state.helpUsData || initialState.helpUsData
);
export const scrapedData = (state = initialState) => (
  state.scrapedData || initialState.scrapedData
);
export const getScraperStatus = (state = initialState) => (
  state.getScraperStatus || initialState.getScraperStatus
);
export const getScraperErrors = (state = initialState) => (
  state.getScraperErrors || initialState.getScraperErrors
);

export const postScraperStatus = (state = initialState) => (
  state.postScraperStatus || initialState.postScraperStatus
);
export const postScraperErrors = (state = initialState) => (
  state.postScraperErrors || initialState.postScraperErrors
);

export const postHelpUsStatus = (state = initialState) => (
  state.postHelpUsStatus || initialState.postHelpUsStatus
);
export const postHelpUsErrors = (state = initialState) => (
  state.postHelpUsErrors || initialState.postHelpUsErrors
);
