import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import camelCase from 'lodash.camelcase';
import history from '../helpers/history';
import { IntlReducer as Intl } from 'react-redux-multilingual'

const reducers = {};
const req = require.context('.', true, /\.\/.+\/reducer\.js$/);

req.keys().forEach(key => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'));
  reducers[storeName] = req(key).default;
});

reducers.router = connectRouter(history);

export default combineReducers({ ...reducers, Intl });
