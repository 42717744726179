import { initialState } from './selectors';

import {
  INIT_VALUES_SUCCESS,

  GET_CAMPAIGN_REQUEST,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  
  GET_STATISTIC_SUCCESS,

  GET_CAMPAIGN_LIST_REQUEST,
  GET_CAMPAIGN_LIST_SUCCESS,
  GET_CAMPAIGN_LIST_FAILURE,

  CREATE_CAMPAIGN_REQUEST,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,

  UPDATE_CAMPAIGN_REQUEST,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAILURE,

  DELETE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
} from './actions';

export default function (state = initialState, action = {}) {

  switch (action.type) {
    case INIT_VALUES_SUCCESS:
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach(stateName => {
          if (initialState[stateName] !== undefined) {
            state[stateName] = initialState[stateName];
          }
        });
      }
      return {
        ...state,
      };


    // Get Campaign List
    case GET_CAMPAIGN_LIST_REQUEST:
      return {
        ...state,
        getCampaignListStatus: 'running',
      };
    case GET_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        campaignList: action.payload.campaignList,
        getCampaignListStatus: 'success',
      };
    case GET_CAMPAIGN_LIST_FAILURE:
      return {
        ...state,
        getCampaignListStatus: 'failure',
        getCampaignListErrors: action.payload.errors,
      };

    // Create Campaign
    case CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        createCampaignStatus: 'running',
      };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload.campaign,
        createCampaignStatus: 'success',
      };
    case CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        createCampaignStatus: 'failure',
        createCampaignErrors: action.payload.errors,
      };

    // Update Campaign
    case UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        updateCampaignStatus: 'running',
      };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: action.payload.campaign,
        updateCampaignStatus: 'success',
      };
    case UPDATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        updateCampaignStatus: 'failure',
        updateCampaignErrors: action.payload.errors,
      };

    case GET_CAMPAIGN_REQUEST:
      return {
        ...state,
        getCampaignStatus: 'running',
      };

    case GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        getCampaignStatus: 'success',
        campaignItem: action.payload.campaign,
      };

    case GET_CAMPAIGN_FAILURE:
      return {
        ...state,
        getCampaignStatus: 'failure',
        getCampaignErrors: action.payload.errors,
      };
    
    case DELETE_CAMPAIGN_REQUEST:
      return {
        ...state,
        deleteCampaignStatus: 'running',
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        deleteCampaignStatus: 'success',
        campaignList: action.payload.campaigns,
        deleteCampaign: action.payload.campaign,
      };
    case DELETE_CAMPAIGN_FAILURE:
      return {
        ...state,
        deleteCampaignStatus: 'failure',
        campaignList: action.payload.campaigns,
        deleteCampaignErrors: action.payload.errors,
      } ;

      case GET_STATISTIC_SUCCESS:
        return {
          ...state,
          campaignStatistic: action.payload.campaignStatistic,
        }; 

    default:
      return state;
  }
}
