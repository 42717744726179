import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as api from '../../utils/api';
import extractErrors from '../../utils/error';

import {
  INIT_VALUES,
  GET_ADS_LIST_REQUEST,
  CREATE_ADS_REQUEST,
  UPDATE_ADS_REQUEST,
  adsActions,
} from './actions';

export function * initValues({ payload }) {
  yield put(adsActions.initValuesSuccess(payload));
}

export function * getAdsList({ payload }) {
  try {
    const response = yield call(api.getAdsList, payload);
    const adsList = response.data.adsList;
    yield put(adsActions.getAdsListSuccess({ adsList }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(adsActions.getAdsListFailure({ errors }));
  }
}

export function * createAds({ payload }) {
  try {
    const response = yield call(api.createAds, payload);
    const ads = response.data;
    localStorage.setItem('currentAds', JSON.stringify(ads));
    yield put(adsActions.createAdsSuccess({ ads }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(adsActions.createAdsFailure({ errors }));
  }
}

export function * updateAds({ payload }) {
  try {
    const response = yield call(api.updateAds, payload);
    const ads = response.data;
    localStorage.setItem('currentAds', JSON.stringify(ads));
    yield put(adsActions.updateAdsSuccess({ ads }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(adsActions.updateAdsFailure({ errors }));
  }
}

export function * markAsSold({ payload }) {
  try {
    const response = yield call(api.updateAds, payload);
    const ads = response.data.ads;
    yield put(adsActions.updateAdsSuccess({ ads }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(adsActions.updateAdsFailure({ errors }));
  }
}

export default function * () {
  yield all([
    takeLatest(INIT_VALUES, initValues),
    takeLatest(GET_ADS_LIST_REQUEST, getAdsList),
    takeLatest(CREATE_ADS_REQUEST, createAds),
    takeLatest(UPDATE_ADS_REQUEST, updateAds),
    takeLatest(UPDATE_ADS_REQUEST, markAsSold),
  ]);
}
