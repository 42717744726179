import { initialState } from './selectors';

import {
  INIT_VALUES_SUCCESS,

  GET_SCRAPER_SUCCESS,
  GET_SCRAPER_REQUEST,
  GET_SCRAPER_FAILURE,

  POST_SCRAPER_REQUEST,
  POST_SCRAPER_SUCCESS,
  POST_SCRAPER_FAILURE,

  POST_HELPUS_REQUEST,
  POST_HELPUS_SUCCESS,
  POST_HELPUS_FAILURE,
} from './actions';

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case INIT_VALUES_SUCCESS:
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach(stateName => {
          if (initialState[stateName] !== undefined) {
            state[stateName] = initialState[stateName];
          }
        });
      }
      return {
        ...state,
      };

    case GET_SCRAPER_REQUEST:
      return {
        ...state,
        getScraperStatus: 'running',
      };
    case GET_SCRAPER_SUCCESS:
      return {
        ...state,
        scrapedData: action.payload.scraper,
        getScraperStatus: 'success',
      };
    case GET_SCRAPER_FAILURE:
      return {
        ...state,
        getScraperStatus: 'failure',
        getScraperErrors: action.payload.errors,
      };

    case POST_SCRAPER_REQUEST:
      return {
        ...state,
        postScraperStatus: 'running',
      };
    case POST_SCRAPER_SUCCESS:
      return {
        ...state,
        scrapedData: action.payload.scraper,
        postScraperStatus: 'success',
      };
    case POST_SCRAPER_FAILURE:
      return {
        ...state,
        postScraperStatus: 'failure',
        postScraperErrors: action.payload.errors,
      };
    
    case POST_HELPUS_REQUEST:
      return {
        ...state,
        postHelpUsStatus: 'running',
      };
    case POST_HELPUS_SUCCESS:
      return {
        ...state,
        postHelpUsStatus: 'success',
        helpUsData: action.payload.helpus,
      };
    case POST_HELPUS_FAILURE:
      return {
        ...state,
        postHelpUsStatus: 'failure',
        postHelpUsErrors: action.payload.errors,
      };
    default:
      return state;
  }
}
