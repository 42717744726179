export const initialState = {
  adsList: null,
  getAdsListStatus: 'idle',
  getAdsListErrors: [],
  ads: null,
  createAdsStatus: 'idle',
  createAdsErrors: [],
  updateAdsStatus: 'idle',
  updateAdsErrors: [],
};

export const adsList = (state = initialState) => (
  state.adsList || initialState.adsList
);
export const getAdsListStatus = (state = initialState) => (
  state.getAdsListStatus || initialState.getAdsListStatus
);
export const getAdsListErrors = (state = initialState) => (
  state.getAdsListErrors || initialState.getAdsListErrors
);
export const ads = (state = initialState) => (
  state.ads || initialState.ads
);
export const createAdsStatus = (state = initialState) => (
  state.createAdsStatus || initialState.createAdsStatus
);
export const createAdsErrors = (state = initialState) => (
  state.createAdsErrors || initialState.createAdsErrors
);
export const updateAdsStatus = (state = initialState) => (
  state.updateAdsStatus || initialState.updateAdsStatus
);
export const updateAdsErrors = (state = initialState) => (
  state.updateAdsErrors || initialState.updateAdsErrors
);