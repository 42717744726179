import { initialState } from './selectors';
import { replace } from 'connected-react-router';
import history from '../../helpers/history';

import {
  INIT_VALUES_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  PHONE_VERIFY_REQUEST,
  PHONE_VERIFY_SUCCESS,
  PHONE_VERIFY_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_2FA_REQUEST,
  LOGIN_2FA_SUCCESS,
  LOGIN_2FA_FAILURE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  EMAIL_CONFIRM_REQUEST,
  EMAIL_CONFIRM_SUCCESS,
  EMAIL_CONFIRM_FAILURE,
  RESEND_CONFIRM_REQUEST,
  RESEND_CONFIRM_SUCCESS,
  RESEND_CONFIRM_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_SECURITY_REQUEST,
  UPDATE_USER_SECURITY_SUCCESS,
  UPDATE_USER_SECURITY_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  ADD_CARD_REQUEST,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILURE,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
} from './actions';

export default function (state = initialState, action = {}) {
  let isConfirmed = false;
  if (action.payload && action.payload.errors) {
    isConfirmed = action.payload.errors.filter(error => error.code === 613).length !== 0;
  }

  switch (action.type) {
    case INIT_VALUES_SUCCESS:
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach(stateName => {
          if (initialState[stateName] !== undefined) {
            state[stateName] = initialState[stateName];
          }
        });
      }
      return {
        ...state,
      };
    // Register
    case CREATE_USER_REQUEST:
      return {
        ...state,
        registerStatus: 'running',
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        user: null,
        registerStatus: 'success',
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        registerStatus: 'failure',
        registerErrors: action.payload.errors,
      };
    // Phone Verify
    case PHONE_VERIFY_REQUEST:
      return {
        ...state,
        phoneVerifyStatus: 'running',
      };
    case PHONE_VERIFY_SUCCESS:
      return {
        ...state,
        phoneVerifyStatus: 'success',
      };
    case PHONE_VERIFY_FAILURE:
      return {
        ...state,
        phoneVerifyStatus: 'failure',
        phoneVerifyErrors: action.payload.errors,
      };
    // Login
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loginStatus: 'running',
        loginErrors: [],
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loginStatus: 'success',
        profileStatus: 'success',
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loginStatus: 'failure',
        loginErrors: action.payload.errors,
        user: null,
      };
    // Login 2FA
    case LOGIN_2FA_REQUEST:
      return {
        ...state,
        login2FAStatus: 'running',
        login2FAErrors: [],
      };
    case LOGIN_2FA_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        login2FAStatus: 'success',
        loginStatus: 'success',
        profileStatus: 'success',
      };
    case LOGIN_2FA_FAILURE:
      return {
        ...state,
        user: action.payload.user,
        login2FAStatus: 'failure',
        login2FAErrors: action.payload.errors,
      };
    // Profile
    case USER_PROFILE_REQUEST:
      return {
        ...state,
        profileStatus: 'running',
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        profileStatus: 'success',
      };
    case USER_PROFILE_FAILURE:
      return {
        ...state,
        profileStatus: 'failure',
        user: null,
      };
    // Logout
    case LOGOUT_REQUEST:
      return {
        ...state,
        logoutStatus: 'running',
      };
    case LOGOUT_SUCCESS: {
      const authFailed = action.payload && action.payload.reason && action.payload.reason === 401;
      const loginSuccess = state.loginStatus === 'success' && state.logoutStatus !== 'running';
      const expired = authFailed && loginSuccess;
      if (authFailed) {
        if (history.includes('/login')) {
          replace('/login');
        } else {
          replace(`/login?redirect=${history.route}`);
        }
      }
      return {
        ...state,
        user: null,
        logoutStatus: expired ? 'session_expired' : 'success',
        loginStatus: 'idle',
        login2FAStatus: 'idle',
      };
    }
    case LOGOUT_FAILURE:
      return {
        ...state,
        user: null,
        loginStatus: 'idle',
        login2FAStatus: 'idle',
        logoutStatus: 'failure',
        logoutErrors: action.payload.errors,
      };
    // Forgot
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotStatus: 'running',
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotStatus: 'success',
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotStatus: 'failure',
        forgotErrors: action.payload.errors,
      };
    // Confirm Email
    case EMAIL_CONFIRM_REQUEST:
      return {
        ...state,
        emailConfirmStatus: 'running',
      };
    case EMAIL_CONFIRM_SUCCESS:
      return {
        ...state,
        emailConfirmStatus: 'success',
      };
    case EMAIL_CONFIRM_FAILURE:
      return {
        ...state,
        emailConfirmStatus: isConfirmed ? 'success' : 'failure',
        emailConfirmErrors: action.payload.errors,
      };
    // Resend Confirm Email
    case RESEND_CONFIRM_REQUEST:
      return {
        ...state,
        resendConfirmStatus: 'running',
      };
    case RESEND_CONFIRM_SUCCESS:
      return {
        ...state,
        resendConfirmStatus: 'success',
      };
    case RESEND_CONFIRM_FAILURE:
      return {
        ...state,
        resendConfirmStatus: 'failure',
        resendConfirmErrors: action.payload.errors,
      };
    // Reset Password
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPwdStatus: 'running',
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPwdStatus: 'success',
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPwdStatus: 'failure',
        resetPwdErrors: action.payload.errors,
      };
    // Profile
    case UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        updateProfileStatus: 'running',
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        updateProfileStatus: 'success',
      };
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileStatus: 'failure',
        updateProfileErrors: action.payload.errors,
      };
    // Security
    case UPDATE_USER_SECURITY_REQUEST:
      return {
        ...state,
        updateSecurityStatus: 'running',
      };
    case UPDATE_USER_SECURITY_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        updateSecurityStatus: 'success',
      };
    case UPDATE_USER_SECURITY_FAILURE:
      return {
        ...state,
        updateSecurityStatus: 'failure',
        updateSecurityErrors: action.payload.errors,
      };
    // Change Password
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePwdStatus: 'running',
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePwdStatus: 'success',
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePwdStatus: 'failure',
        changePwdErrors: action.payload.errors,
      };
    case ADD_CARD_REQUEST:
      return {
        ...state,
        addCardStatus: 'running',
      };
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        addCardStatus: 'success',
        user: {
          ...state.user,
          card: action.payload.card,
        },
      };
    case ADD_CARD_FAILURE:
      return {
        ...state,
        addCardStatus: 'failure',
        addCardErrors: action.payload.errors,
      };
    case DELETE_CARD_REQUEST:
      return {
        ...state,
        deleteCardStatus: 'running',
      };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          card: null,
        },
        deleteCardStatus: 'success',
      };
    case DELETE_CARD_FAILURE:
      return {
        ...state,
        deleteCardStatus: 'failure',
        deleteCardErrors: action.payload.errors,
      };
    default:
      return state;
  }
}
