import { all, put, call, takeLatest } from "redux-saga/effects";

import * as api from "utils/api";
import extractErrors from "utils/error";
import {
  INIT_VALUES,
  GET_SCRAPER_REQUEST,
  POST_SCRAPER_REQUEST,

  POST_HELPUS_REQUEST,

  scraperActions,
} from "./actions";

export function* initValues({ payload }) {
  yield put(scraperActions.initValuesSuccess(payload));
}

export function* getScrapedData({ payload }) {
  try {
    const response = yield call(api.getScrapedData, payload);
    const scraper = response.data;
    yield put(scraperActions.getScraperSuccess({ scraper }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(scraperActions.getScraperFailure({ errors }));
  }
}

export function* postScrapedData({ payload }) {
  try {
    const response = yield call(api.postScrapedData, payload);
    const scraper = response.data;
    yield put(scraperActions.postScraperSuccess({ scraper }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(scraperActions.postScraperFailure({ errors }));
  }
}

export function* postHelpUsData({ payload }) {
  try {
    const response = yield call(api.helpUs, payload);
    const helpus = response.data;
    yield put(scraperActions.postHelpUsSuccess({ helpus }));
  } catch (err) {
    const errors = extractErrors(err);
    yield put(scraperActions.postHelpUsFailure({ errors }));
  }
}

export default function* () {
  yield all([
    takeLatest(INIT_VALUES, initValues),
    takeLatest(GET_SCRAPER_REQUEST, getScrapedData),
    takeLatest(POST_SCRAPER_REQUEST, postScrapedData),
    takeLatest(POST_HELPUS_REQUEST, postHelpUsData),
  ]);
}
